import React from "react"
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Head = ({ title, description }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)
    return (
        <Helmet>
            <title>{`${title} | ${data.site.siteMetadata.title}`}</title>
            <meta name="description" content={`${description}`} />
            {/* <meta name="google-site-verification" content="RFGdmSsuPL3uc4EEF_5dNS1IUui270pSTvTa8r6LGa4" /> */}
        </Helmet>
    )
}

export default Head


