import React from "react"
import { Link } from "gatsby"
import logo from "../images/logos/logo1.jpg"
import AmexLogo from "../assets/images/icon/american-express.png"
import VisaLogo from "../assets/images/icon/visa.png"
import PayPalLogo from "../assets/images/icon/paypal.png"
import DiscoverLogo from "../assets/images/icon/discover.png"
import MasterCardLogo from "../assets/images/icon/mastercard.png"

const Footer = () => {
  return (
    <footer className="footer-light pet-layout-footer">
      <div className="white-layout">
        <div className="container">
          <section className="small-section">
            <div className="footer-theme2">
              {/* <div className="row footer-theme2"> */}
              <div className="col">
                <div className="footer-link link-white">
                  <div className="footer-brand-logo">
                    <Link to="/">
                      {/* <img src={require("../assets/images/icon/logo/14.png")} className="img-fluid blur-up lazyload" alt="" /> */}
                      <img
                        src={logo}
                        className="img-fluid lazyload footer-logo"
                        alt="ThrowThings.com logo"
                      />
                    </Link>
                  </div>
                  {/* <div className="social-white">
                                        <ul>
                                            <li>
                                                <Link to="#"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to="#"><i className="fa fa-google-plus" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to="#"><i className="fa fa-twitter" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to="#"><i className="fa fa-instagram" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to="#"><i className="fa fa-rss" aria-hidden="true"></i></Link>
                                            </li>
                                        </ul>
                                    </div> */}
                  {/* <div className="footer-title footer-mobile-title">
                    <h4>my account</h4>
                  </div> */}
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <Link to="/about">about</Link>
                      </li>
                      <li>
                        <Link to="/shipping">shipping</Link>
                      </li>
                      <li>
                        <Link to="/returns">returns</Link>
                      </li>

                      <li>
                        <Link to="/privacy">privacy policy</Link>
                      </li>
                      <li>
                        <a href="https://blog.throwthings.com">
                          our blog "throw and tell"
                        </a>
                      </li>
                      <li>
                        <Link to="/sitemap">site map</Link>
                      </li>
                      <li>
                        <Link to="/nice-words-thrown-our-way">
                          nice words thrown our way
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="#">cart</Link>
                      </li>
                      <li>
                        <Link to="#">my order</Link>
                      </li>
                      <li>
                        <Link to="#">FAQ</Link>
                      </li>
                      <li>
                        <Link to="#">new product</Link>
                      </li>
                      <li>
                        <Link to="#">featured product</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="sub-footer black-subfooter ">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-md-6 col-sm-12">
              <div className="footer-end">
                <p>
                  <i className="fa fa-copyright" aria-hidden="true"></i> 2001 -
                  2024 ThrowThings.com
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12">
              <div className="payment-card-bottom">
                <ul>
                  <li>
                    {/* <Link to="#"> */}
                    <img
                      src={VisaLogo}
                      // src={require("../assets/images/icon/visa.png")}
                      alt="Visa logo"
                    />
                    {/* </Link> */}
                  </li>
                  <li>
                    {/* <Link to="#"> */}
                    <img
                      src={MasterCardLogo}
                      // src={require("../assets/images/icon/mastercard.png")}
                      alt="Mastercard Logo"
                    />
                    {/* </Link> */}
                  </li>
                  <li>
                    {/* <Link to="#"> */}
                    <img
                      src={PayPalLogo}
                      // src={require("../assets/images/icon/paypal.png")}
                      alt="PayPal logo"
                    />
                    {/* </Link> */}
                  </li>
                  <li>
                    {/* <Link to="#"> */}
                    <img
                      src={AmexLogo}
                      // src={require("../assets/images/icon/american-express.png")}
                      alt="American Express Logo"
                    />
                    {/* </Link> */}
                  </li>
                  <li>
                    {/* <Link to="#"> */}
                    <img
                      src={DiscoverLogo}
                      // src={require("../assets/images/icon/discover.png")}
                      alt="Discover Card logo"
                    />
                    {/* </Link> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
